<template>
  <div class="documents-container">
    <DocumentTypeButtons
      :certificateDocument="certificateDocument"
      @selectedTypeOfDocument="selectedTypeOfDocument"
    />
    <div class="insurance-documents" v-loading="loading">
      <div class="document-list" v-if="shouldDisplayDocumentList">
        <DocumentList
          :documents="filteredDocuments"
          :tokenInfo="tokenInfo"
          :currentType="selectedTypeDocument"
          :isCargoCare="true"
          :insuranceId="selectedInsurance.id"
          @shareDocument="shareDocument"
          @getSelectedDocument="getSelectedDocument"
          @disableScroll="disableScroll"
        />
      </div>
      <div
        class="document-viewer"
        v-if="selectedTypeDocument === 'certificate' && certificateDocument"
      >
        <button class="buttons-container__button" @click="handlerDocumentViewer(true)">
          <i class="ion ion-expand-outline"></i>
        </button>
        <pdf :src="certificateDocument?.url" />
      </div>
      <div class="not-found" v-if="documents.length === 0">
        <el-empty :description="'There are no available documents at this moment.'"></el-empty>
      </div>
    </div>
    <div class="document-viewer-container" v-if="showViewerDocument">
      <InsuranceDocumentViewer
        :document="document"
        @shareDocument="shareDocument"
        @handlerDocumentViewer="handlerDocumentViewer"
        @enableScroll="enableScroll"
      />
    </div>
    <CustomModal
      :centered="true"
      :no-close-on-backdrop="true"
      size="md"
      modalTitle="Share document"
      v-model="shareDocumentModal"
    >
      <template #modal-content>
        <ShareDocument
          :insuranceId="selectedInsurance.id"
          :documentInformation="documentToShare"
          :documentType="selectedTypeDocument"
          :isCargoCare="true"
          @closeShareDocumentModal="closeShareDocumentModal"
        />
      </template>
    </CustomModal>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import DocumentList from "../../../../LoadDetails/components/documents/DocumentsList.vue";
import CustomModal from "../../../../../components/CustomModal.vue";
import ShareDocument from "../../../../../components/ShareDocument.vue";
import DocumentTypeButtons from "./components/DocumentTypeButtons.vue";
import InsuranceDocumentViewer from "../../../MyInsurance/components/InsuranceDocumentViewer.vue";

export default {
  name: "InsuranceDocuments",
  props: {
    selectedInsurance: Object,
  },
  components: {
    DocumentList,
    ShareDocument,
    CustomModal,
    pdf,
    DocumentTypeButtons,
    InsuranceDocumentViewer,
  },
  data() {
    return {
      documents: [],
      filteredDocuments: [],
      tokenInfo: null,
      loading: false,
      shareDocumentModal: false,
      documentToShare: null,
      certificateDocument: null,
      showViewerDocument: false,
      selectedTypeDocument: "",
      document: ""
    };
  },
  created() {
    this.tokenInfo = this.$store.getters["login/getTokenInfo"];
    this.getDocuments();
  },
  computed: {
    shouldDisplayDocumentList() {
      const hasDocuments = this.documents.length > 0;
      const isAllDocsActive = this.selectedTypeDocument === "allDocs";
      const isMyDocsActive = this.selectedTypeDocument === "myDocs";
      return hasDocuments && (isAllDocsActive || isMyDocsActive);
    },
  },
  watch: {
    selectedTypeDocument: {
      immediate: true,
      deep: true,
      handler() {
        if (this.selectedTypeDocument === "allDocs") {
          this.filteredDocuments = this.documents;
        }
        if (this.selectedTypeDocument === "myDocs") {
          this.filteredDocuments = this.documents.filter(
            (document) => !document.name.startsWith("certificate/")
          );
        }
      },
    },
    documents: {
      deep: true,
      immediate: true,
      handler() {
        this.certificateDocument = this.findCertificateDocument(this.documents);
      },
    },
  },
  methods: {
    getDocumentsFromVuex() {
      const documents = this.$store.getters["insurance/getDocumentsById"](
        this.selectedInsurance.id
      );
      return documents;
    },
    async getInsuranceDocuments() {
      const documents = await this.$store.dispatch(
        "insurance/generateDocuments",
        this.selectedInsurance.id
      );
      return documents;
    },
    async getDocuments() {
      this.loading = true;
      this.documents = await this.getInsuranceDocuments();
      this.filteredDocuments = this.documents;
      this.loading = false;
    },
    findCertificateDocument(documents) {
      return documents.find((doc) => doc.name.startsWith("certificate/"));
    },
    selectedTypeOfDocument(type) {
      this.selectedTypeDocument = type;
    },
    shareDocument(document) {
      this.documentToShare = document;
      this.handlerShareModal(true);
    },
    handlerShareModal(data) {
      this.shareDocumentModal = data;
    },
    closeShareDocumentModal() {
      this.handlerShareModal(false);
    },
    handlerDocumentViewer(data) {
      this.showViewerDocument = data;
      if (this.selectedTypeDocument === "certificate") {
        this.document = this.certificateDocument;
      }
      if (data) {
        this.disableScroll();
      }
    },
    disableScroll() {
      document.body.style.overflow = "hidden";
      document.body.style.userSelect = "none";
    },
    enableScroll() {
      document.body.style.overflow = "auto";
      document.body.style.userSelect = "auto";
    },
    getSelectedDocument(doc, data) {
      this.document = doc;
      this.showViewerDocument = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.documents-container {
  margin-top: 12px;
}
.document-list {
  width: 100%;
  height: 100%;
}
.insurance-documents {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto;
  background-color: $color-white;
  border-radius: 8px;
  position: relative;
  width: 100%;
  height: 100%;
}
.buttons-container {
  position: absolute;
  bottom: 0;
  right: 0;
}
.document-viewer {
  width: 97%;
  height: 100%;
  position: relative;
}
.buttons-container {
  &__button {
    position: absolute;
    z-index: 10;
    right: 0;
    top: 10px;
    width: 60px;
    height: 30px;
    border-radius: 7px;
    background: $color-white;
    color: $color-primary-company;
    border: 1px solid $color-border-container;
    &:hover {
      color: $color-white;
      background: $color-primary-company;
      transition: 0.2s all;
      border: $color-white;
    }
  }
}
::v-deep .documents-list-content {
  min-height: 250px;
}
</style>
