<template>
  <ul class="document-tabs">
    <li
      class="document-tabs__item"
      v-for="(tab, index) in filteredTabs"
      :key="index"
      :label="tab.label"
      :name="tab.name"
      :class="{ is_active: activeButton === tab.name }"
    >
      <button
        class="document-tabs-button"
        @click="handleTabClick(tab.name)"
      >
        {{ tab.label }}
      </button>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DocumentTypeButtons',
  props: {
    certificateDocument: Object
  },
  data() {
    return {
      tabs: [
        {
          label: "ALL DOCS",
          name: "allDocs",
          condition: () => true,
        },
        {
          label: "MY DOCS",
          name: "myDocs",
          condition: () => true,
        },
        {
          label: "CERTIFICATE",
          name: "certificate",
          condition: () => this.certificateDocument,
        },
      ],
      activeButton: "allDocs",
    };
  },
  computed: {
    filteredTabs() {
      return this.tabs.filter((tab) => tab.condition());
    },
  },
  created() {
    this.$emit('selectedTypeOfDocument', "allDocs");
  },
  methods: {
    handleTabClick(tabName) {
      this.activeButton = tabName;
      this.$emit('selectedTypeOfDocument', tabName);
    }
  }
};
</script>

<style lang="scss" scoped>
.document-tabs {
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
}
.document-tabs-button {
  @include button-tag();
}
.is_active .document-tabs-button {
  background-color: $color-border-container;
  color: $color-white;
}
</style>
